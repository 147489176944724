
import React from "react";
import { Flex } from "@chakra-ui/react";

export function Separator(props) {
  const { variant, children, ...rest } = props;
  return (
    <Flex
      h='1px'
      w='100%'
      bg='#1a1f3d'
      {...rest}>
      {children}
    </Flex>
  );
}
