import React, { Component } from "react";
import Chart from "react-apexcharts";
import baseUrl from "utils/constants";
import { Spinner, Box } from "@chakra-ui/react";

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
            fontFamily: "Plus Jakarta Display",
          },
          onDatasetHover: {
            style: {
              fontSize: "12px",
              fontFamily: "Plus Jakarta Display",
            },
          },
          theme: "dark",
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            style: {
              colors: "#fff",
              fontSize: "12px",
              fontFamily: "Plus Jakarta Display",
            },
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            style: {
              colors: "#fff",
              fontSize: "12px",
              fontFamily: "Plus Jakarta Display",
            },
            formatter: (value) => value.toFixed(2), // Format to two decimal places
          },
          min: 0,
          forceNiceScale: true,
        },
        grid: {
          show: true,
          borderColor: "#56577A",
        },
        fill: {
          colors: ["#8884d8", "#82ca9d"], // Colors for buying and selling rates
        },
        dataLabels: {
          enabled: false, // Disable data labels on bars to reduce overlap
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            columnWidth: "45%",
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 0,
                },
              },
            },
          },
        ],
        legend: {
          position: "top",
          labels: {
            colors: "#fff",
            useSeriesColors: true,
          },
        },
      },
      dataLoaded: false, // To track if data is loaded
    };
  }

  fetchData = () => {
    const { currency, timeRange } = this.props;

    fetch(
      `${baseUrl}/exchange_rate_comparison?currency=${currency}&time_range=${timeRange}`
    )
      .then((response) => response.json())
      .then((data) => {
        // Prepare the data for the chart
        const categories = data.data.map((bank) => bank.bank);
        const buyingRates = data.data.map((bank) =>
          parseFloat(bank.rates[bank.rates.length - 1].buying)
        );
        const sellingRates = data.data.map((bank) =>
          parseFloat(bank.rates[bank.rates.length - 1].selling)
        );

        this.setState({
          chartData: [
            {
              name: "Buying Rate",
              data: buyingRates,
            },
            {
              name: "Selling Rate",
              data: sellingRates,
            },
          ],
          chartOptions: {
            ...this.state.chartOptions,
            xaxis: {
              ...this.state.chartOptions.xaxis,
              categories: categories,
            },
          },
          dataLoaded: true,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        this.setState({ dataLoaded: true }); // Even in case of an error, stop the loading state
      });
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // Check if the currency or timeRange props have changed
    if (
      prevProps.currency !== this.props.currency ||
      prevProps.timeRange !== this.props.timeRange
    ) {
      this.setState({ dataLoaded: false }, () => this.fetchData()); // Reset the loading state and fetch new data
    }
  }

  render() {
    return (
      <Box position="relative" width="100%" height="400px">
        <Chart
          options={this.state.chartOptions}
          series={this.state.chartData}
          type="bar"
          width="100%"
          height="100%"
        />
        {!this.state.dataLoaded && (
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            pointerEvents="none" // Prevents the overlay from blocking interactions
          >
            <Spinner size="xl" color="blue.500" />
          </Box>
        )}
      </Box>
    );
  }
}

export default BarChart;
