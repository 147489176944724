import React, { useState, useEffect } from 'react';
import {
  Flex,
  Input,
  Button,
  Select,
  Table,
  Tbody,
  Th,
  Tr,
  Td,
  Alert,
  AlertIcon,
  Text,
  Box,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import baseUrl from 'utils/constants';

const currencyCodesToNames = {
  "USD": "US Dollar",
  "EUR": "Euro",
  "GBP": "Pound Sterling",
  "CAD": "Canadian Dollar",
  "AED": "UAE Dirham",
  "SAR": "Saudi Riyal",
  "CNY": "Chinese Yuan",
  "JPY": "Japanese Yen",
  "CHF": "Swiss Franc",
  "AUD": "Australian Dollar",
  "SEK": "Swedish Krona",
  "NOK": "Norwegian Krone",
  "DKK": "Danish Krone",
  "ZAR": "South African Rand",
  "INR": "Indian Rupee",
  "KES": "Kenyan Shilling",
  "KWD": "Kuwaiti Dinar",
  "DJF": "Djiboutian Franc",
};

const CurrencyConverterPage = () => {
  const [exchangeRates, setExchangeRates] = useState([]);
  const [amount, setAmount] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [conversionResults, setConversionResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const query = new URLSearchParams(useLocation().search);
  const queryAmount = query.get('amount');
  const queryCurrency = query.get('currency');

  useEffect(() => {
    fetch(`${baseUrl}/exchange_rates_for_all_banks`)
      .then((response) => response.json())
      .then((data) => {
        setExchangeRates(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching exchange rates:', error);
        setError('Failed to load exchange rates. Please try again later.');
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (queryAmount && queryCurrency) {
      setAmount(queryAmount);
      setSelectedCurrency(queryCurrency);
      handleConvert(queryAmount, queryCurrency);
    }
  }, [queryAmount, queryCurrency, exchangeRates]);

  const handleConvert = (amt = amount, currency = selectedCurrency) => {
    if (!amt || !currency) {
      setError('Please enter an amount and select a currency.');
      return;
    }
    setError(null);

    const results = exchangeRates.map((bank) => {
      const rate = bank.rates.find((r) => r['Currency Code'] === currency);
      if (!rate) return null;

      const buyingRate = parseFloat(rate.Buying);

      return {
        bankName: bank.bank_name,
        amountReceived: parseFloat(amt) * buyingRate,
        buyingRate,
      };
    });

    const filteredResults = results.filter((result) => result !== null);

    // Sort results from best to worst and highlight the best rate
    const sortedResults = filteredResults
      .sort((a, b) => b.amountReceived - a.amountReceived)
      .map((result, index) => ({
        ...result,
        bankName:
          index === 0
            ? `${result.bankName} 🔥 (best rate)`
            : result.bankName,
        amountReceived: new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
        }).format(result.amountReceived),
      }));

    setConversionResults(sortedResults);
  };

  return (
    <>
      <Helmet>
        <title>{`Convert ${queryAmount || ''} ${queryCurrency || ''} to ETB - Birr Value`}</title>
        <meta
          name="description"
          content={`Convert ${queryAmount || ''} ${queryCurrency || ''} to Ethiopian Birr with the best rates from major Ethiopian banks.`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Currency Converter - Birr Value",
            "url": "https://birrvalue.com/currency-converter",
            "description": "Convert Ethiopian Birr (ETB) to various foreign currencies using the best exchange rates from major Ethiopian banks.",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://birrvalue.com/currency-converter?amount={amount}&currency={currency}",
              "query-input": [
                {
                  "@type": "PropertyValueSpecification",
                  "valueRequired": true,
                  "valueName": "amount"
                },
                {
                  "@type": "PropertyValueSpecification",
                  "valueRequired": true,
                  "valueName": "currency"
                }
              ]
            }
          })}
          </script>
      </Helmet>
      <Flex direction="column" p="4" pt={{ base: '80px', md: '100px' }} color="#fff">
        <Text fontSize="28px" color="#fff" fontWeight="bold" mb="18px">
          Currency Converter
        </Text>

        {error && (
          <Alert status="error" variant="solid" mb="4">
            <AlertIcon />
            {error}
          </Alert>
        )}

        <Flex direction={{ base: 'column', md: 'row' }} mb="4" gap="4">
          <Input
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type="number"
          />
          <Select
            placeholder="Select Currency"
            value={selectedCurrency}
            onChange={(e) => setSelectedCurrency(e.target.value)}
          >
            {Object.entries(currencyCodesToNames).map(([code, name]) => (
              <option key={code} value={code}>
                {`${code} - ${name}`}
              </option>
            ))}
          </Select>
          <Button
            onClick={() => handleConvert()}
            bg="#2b2f4a"
            _hover={{ bg: "#333652" }}
            _active={{ bg: "#24273d" }}
            _focus={{ boxShadow: "none" }}
            color="white"
            border="1px solid white"
            size={{ base: "sm", md: "md" }}
            p={{ base: "8px", md: "12px" }}
            width={{ base: "100%", md: "auto" }}
          >
            Convert
          </Button>
        </Flex>

        {conversionResults.length > 0 && (
          <Box overflowX="auto">
            <Table variant="simple" colorScheme="white">
              <thead>
                <Tr>
                  <Th fontWeight="bold" color="white">Bank</Th>
                  <Th fontWeight="bold" color="white">Amount Received (ETB)</Th>
                  <Th fontWeight="bold" color="white">Buying Rate</Th>
                </Tr>
              </thead>
              <Tbody>
                {conversionResults.map((result, index) => (
                  <Tr key={index}>
                    <Td>{result.bankName}</Td>
                    <Td>{result.amountReceived}</Td>
                    <Td>{result.buyingRate}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Flex>
    </>
  );
};

export default CurrencyConverterPage;
