import React, { useState, useEffect } from 'react';
import {
  Flex,
  Input,
  Button,
  Select,
  Table,
  Tbody,
  Th,
  Tr,
  Td,
  Skeleton,
  Alert,
  AlertIcon,
  Text,
  Radio,
  RadioGroup,
  Stack,
  Box,
} from '@chakra-ui/react';
import baseUrl from 'utils/constants';

// Currency codes and names dictionary
const currency_codes_to_names = {
  "USD": "US Dollar",
  "EUR": "Euro",
  "GBP": "Pound Sterling",
  "CAD": "Canadian Dollar",
  "AED": "UAE Dirham",
  "SAR": "Saudi Riyal",
  "CNY": "Chinese Yuan",
  "JPY": "Japanese Yen",
  "CHF": "Swiss Franc",
  "AUD": "Australian Dollar",
  "SEK": "Swedish Krona",
  "NOK": "Norwegian Krone",
  "DKK": "Danish Krone",
  "ZAR": "South African Rand",
  "INR": "Indian Rupee",
  "KES": "Kenyan Shilling",
  "KWD": "Kuwaiti Dinar",
  "DJF": "Djiboutian Franc",
};

const CurrencyConverter = () => {
  const [exchangeRates, setExchangeRates] = useState([]);
  const [amount, setAmount] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [conversionResults, setConversionResults] = useState([]);
  const [conversionType, setConversionType] = useState('send'); // 'send' for converting and 'buy' for buying currency
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch exchange rates from the backend API
    fetch(`${baseUrl}/exchange_rates_for_all_banks`)
      .then((response) => response.json())
      .then((data) => {
        setExchangeRates(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching exchange rates:', error);
        setError('Failed to load exchange rates. Please try again later.');
        setIsLoading(false);
      });
  }, []);

  const handleConvert = () => {
    if (!amount || !selectedCurrency) {
      setError('Please enter an amount and select a currency.');
      return;
    }
    setError(null);

    const results = exchangeRates.map((bank) => {
      const rate = bank.rates.find((r) => r['Currency Code'] === selectedCurrency);
      if (!rate) return null;

      const buyingRate = parseFloat(rate.Buying);
      const sellingRate = parseFloat(rate.Selling);

      return {
        bankName: bank.bank_name,
        amountReceived: new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
        }).format(amount * buyingRate), // Amount in ETB the customer receives
        amountToPay: new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 2,
        }).format(amount * sellingRate), // Amount in ETB the customer pays
        buyingRate,
        sellingRate,
      };
    });

    const filteredResults = results.filter((result) => result !== null);

    // Determine the bank with the highest or lowest rate based on conversion type
    const bestBank =
      conversionType === 'send'
        ? filteredResults.reduce((prev, curr) =>
            curr.buyingRate > prev.buyingRate ? curr : prev
          )
        : filteredResults.reduce((prev, curr) =>
            curr.sellingRate < prev.sellingRate ? curr : prev
          );

    // Add fire emoji to the best bank
    const finalResults = filteredResults.map((result) => ({
      ...result,
      bankName:
        result.bankName === bestBank.bankName
          ? `${result.bankName} 🔥 (best rate)`
          : result.bankName,
    }));

    const sortedResults = finalResults.sort((a, b) =>
      conversionType === 'send' ? b.buyingRate - a.buyingRate : a.sellingRate - b.sellingRate
    );

    setConversionResults(sortedResults);
  };

  return (
    <Flex direction="column" p="4" color="#fff">
      <Text fontSize="28px" color="#fff" fontWeight="bold" mb="18px">
        Currency Converter
      </Text>

      <RadioGroup onChange={setConversionType} value={conversionType} mb="4">
        <Stack direction="column" spacing="24px">
          <Box>
            <Radio value="send">Send to Family / Convert</Radio>
            <Text fontSize="sm" color="gray.400">
              Select this option to see how much Ethiopian Birr you or your family will receive when converting foreign currency.
            </Text>
          </Box>
          <Box>
            <Radio value="buy">Buy Currency from Bank</Radio>
            <Text fontSize="sm" color="gray.400">
              Select this option if you need to buy foreign currency for trade, import/export, or travel purposes, showing the cost in Ethiopian Birr.
            </Text>
          </Box>
        </Stack>
      </RadioGroup>

      {error && (
        <Alert status="error" variant="solid" mb="4">
          <AlertIcon />
          {error}
        </Alert>
      )}

      <Flex direction={{ base: 'column', md: 'row' }} mb="4" gap="4">
        <Input
          placeholder="Enter amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          type="number"
        />
        <Select
          placeholder="Select Currency"
          value={selectedCurrency}
          onChange={(e) => setSelectedCurrency(e.target.value)}
        >
          {Object.entries(currency_codes_to_names).map(([code, name]) => (
            <option key={code} value={code}>
              {code} - {name}
            </option>
          ))}
        </Select>
        <Button
          onClick={handleConvert}
          bg="#2b2f4a" // Adjusted to be three steps lighter than the navbar
          _hover={{ bg: "#333652" }} // Slightly lighter shade on hover
          _active={{ bg: "#24273d" }} // Slightly darker on active
          _focus={{ boxShadow: "none" }} // Removing focus outline
          color="white"
          border="1px solid white" // Adds a thin white border
          size={{ base: "sm", md: "md" }} // Responsive size
          p={{ base: "8px", md: "12px" }} // Adjust padding based on screen size
          width={{ base: "100%", md: "auto" }} // Full width on mobile, auto on larger screens
        >
          Convert
        </Button>
      </Flex>

      {conversionResults.length > 0 && (
        <Box overflowX="auto">
          <Table variant="simple" colorScheme="whiteAlpha">
            <thead>
              <Tr>
                <Th>Bank</Th>
                <Th>{conversionType === 'send' ? 'Amount Received (ETB)' : 'Amount to Pay (ETB)'}</Th>
                <Th>{conversionType === 'send' ? 'Buying Rate' : 'Selling Rate'}</Th>
              </Tr>
            </thead>
            <Tbody>
              {conversionResults.map((result, index) => (
                <Tr key={index}>
                  <Td>{result.bankName}</Td>
                  <Td>{conversionType === 'send' ? result.amountReceived : result.amountToPay}</Td>
                  <Td>{conversionType === 'send' ? result.buyingRate : result.sellingRate}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </Flex>
  );
};

export default CurrencyConverter;
