// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticker-wrap {
  width: 100%;
  overflow: hidden;
  background-color: #000;
  color: #fff;
  padding: 10px 0;
  white-space: nowrap;
}

.ticker {
  display: inline-flex;
  animation: ticker 260s linear infinite;
}

.ticker__item {
  padding: 0 15px;
  white-space: nowrap;
}

@keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/Dashboard/ticker.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,sBAAsB;EACtB,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,sCAAsC;AACxC;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".ticker-wrap {\n  width: 100%;\n  overflow: hidden;\n  background-color: #000;\n  color: #fff;\n  padding: 10px 0;\n  white-space: nowrap;\n}\n\n.ticker {\n  display: inline-flex;\n  animation: ticker 260s linear infinite;\n}\n\n.ticker__item {\n  padding: 0 15px;\n  white-space: nowrap;\n}\n\n@keyframes ticker {\n  0% {\n    transform: translateX(0);\n  }\n  100% {\n    transform: translateX(-100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
