import "./ticker.css";
import React, { useEffect, useState } from "react";
import baseUrl from "utils/constants";

const Ticker = () => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await fetch(`${baseUrl}/exchange_rates_for_all_banks`);
        const data = await response.json();

        setTransactions(data);
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };

    fetchExchangeRates();
  }, []);

  const renderTickerItems = () =>
    transactions.map((bank, index) => (
      <div key={index} className="ticker__item">
        <strong>{bank.bank_name}:</strong>&nbsp;
        {bank.rates.map((rate, rateIndex) => (
          <span key={rateIndex}>
            {rate["Currency Code"]} <span style={{ color: "#ff0000" }}>↓</span>{" "}
            {rate.Buying} <span style={{ color: "#00ff00" }}>↑</span>{" "}
            {rate.Selling}
            {rateIndex < bank.rates.length - 1 ? " | " : ""}
          </span>
        ))}
        {index < transactions.length - 1 ? " | " : ""}
      </div>
    ));

  if (transactions.length === 0) {
    return null; // Return nothing if data is not loaded
  }

  return (
    <div className="ticker-wrap">
      <div className="ticker">
        {renderTickerItems()}
        {renderTickerItems()} {/* Render twice for seamless scrolling */}
      </div>
    </div>
  );
};

export default Ticker;
