// PrivacyPolicy.js
import React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Flex direction="column" p={{ base: "4", md: "8" }} color="#fff" bg="#1a1f3d" minHeight="100vh">
      <Box maxWidth="800px" margin="auto">
        <Heading as="h1" size="xl" mb="4" color="teal.200">
          Privacy Policy
        </Heading>
        <Text mb="4" fontSize="sm" color="gray.400">
          **Effective Date:** 09/09/2024
        </Text>
        <Text mb="4">
          At BirrValue.com, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.
        </Text>

        <Heading as="h2" size="md" mb="2" mt="4" color="teal.300">
          1. Information We Collect
        </Heading>
        <Text mb="4">
          - **Personal Information:** We may collect personal information such as your name, email address, and contact details if you voluntarily submit it through our contact forms or subscriptions.
          - **Non-Personal Information:** We collect non-personal information automatically, such as your IP address, browser type, operating system, and usage data, using cookies and similar technologies.
        </Text>

        <Heading as="h2" size="md" mb="2" mt="4" color="teal.300">
          2. How We Use Your Information
        </Heading>
        <Text mb="4">
          We use the information we collect for the following purposes:
          - To provide, operate, and maintain our website and services.
          - To improve, personalize, and expand our website.
          - To communicate with you, including for customer service, updates, and promotional purposes.
          - To monitor and analyze trends, usage, and activities on our website.
          - To comply with legal obligations and protect against fraud.
        </Text>

        <Heading as="h2" size="md" mb="2" mt="4" color="teal.300">
          3. Cookies and Tracking Technologies
        </Heading>
        <Text mb="4">
          BirrValue.com uses cookies and similar tracking technologies to enhance your experience on our website. Cookies are small files stored on your device that help us remember your preferences and understand how you interact with our website. You can choose to accept or reject cookies through your browser settings.
        </Text>

        <Heading as="h2" size="md" mb="2" mt="4" color="teal.300">
          4. Sharing Your Information
        </Heading>
        <Text mb="4">
          We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except in the following circumstances:
          - With service providers who assist us in operating our website and conducting our business, provided they agree to keep your information confidential.
          - When required by law, regulation, or legal request.
          - To protect our rights, privacy, safety, or property, and that of our users and the public.
        </Text>

        <Heading as="h2" size="md" mb="2" mt="4" color="teal.300">
          5. Third-Party Websites
        </Heading>
        <Text mb="4">
          Our website may contain links to third-party websites that are not operated by us. We are not responsible for the content, privacy policies, or practices of any third-party sites or services. We encourage you to review the privacy policies of these websites before providing any personal information.
        </Text>

        <Heading as="h2" size="md" mb="2" mt="4" color="teal.300">
          6. Security of Your Information
        </Heading>
        <Text mb="4">
          We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.
        </Text>

        <Heading as="h2" size="md" mb="2" mt="4" color="teal.300">
          7. Your Privacy Rights
        </Heading>
        <Text mb="4">
          Depending on your location, you may have the following rights concerning your personal information:
          - The right to access, update, or delete the personal information we hold about you.
          - The right to withdraw consent where you have previously given consent to the processing of your personal information.
          - The right to request that we restrict the processing of your personal data under certain conditions.
          To exercise these rights, please contact us at redcolormaildha@gmail.com.
        </Text>

        <Heading as="h2" size="md" mb="2" mt="4" color="teal.300">
          8. Changes to This Privacy Policy
        </Heading>
        <Text mb="4">
          We may update our Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. The updated policy will be posted on this page with the effective date.
        </Text>

        <Heading as="h2" size="md" mb="2" mt="4" color="teal.300">
          9. Contact Us
        </Heading>
        <Text mb="4">
          If you have any questions or concerns about this Privacy Policy, please contact us at:
          <br />
          **Email:** redcolormaildha@gmail.com
          <br />
          **Website:** [https://www.birrvalue.com](https://www.birrvalue.com)
        </Text>
      </Box>
    </Flex>
  );
};

export default PrivacyPolicy;
