import React, { useState, useEffect } from "react";
import {
  SimpleGrid,
  Flex,
  Text,
  Skeleton,
  SkeletonCircle,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import { WalletIcon } from "components/Icons/Icons.js";
import baseUrl from "utils/constants";

function RatesGrid({ selectedBank, selectedCurrency }) {
  const [rates, setRates] = useState({
    day: { average_buying: 0, average_selling: 0 },
    week: { average_buying: 0, average_selling: 0 },
    month: { average_buying: 0, average_selling: 0 },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedBank && selectedCurrency) {
      setIsLoading(true); // Start loading
      setError(null); // Reset error state
      fetch(
        `${baseUrl}/exchange_rate_summary?bank_name=${encodeURIComponent(
          selectedBank
        )}&currency=${encodeURIComponent(selectedCurrency)}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setRates(data);
          setIsLoading(false); // Stop loading
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setError("Failed to load data. Please try again later.");
          setIsLoading(false); // Stop loading even on error
        });
    }
  }, [selectedBank, selectedCurrency]);

  if (error) {
    return (
      <Alert status="error" variant="solid" mb="4">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  return (
    <SimpleGrid ps="10px" gap={{ sm: "12px" }} columns={3}>
      {/* Day */}
      <Flex direction="column" mr="20px">
        <Flex alignItems="center">
          {isLoading ? (
            <SkeletonCircle size="30px" me="6px" />
          ) : (
            <IconBox as="box" h={"30px"} w={"30px"} bg="brand.200" me="6px">
              <WalletIcon h={"15px"} w={"15px"} color="#fff" />
            </IconBox>
          )}
          <Skeleton isLoaded={!isLoading} w="50px">
            <Text fontSize="sm" color="gray.400">
              Day
            </Text>
          </Skeleton>
        </Flex>
        <Skeleton isLoaded={!isLoading}>
          <Text
            fontSize={{ sm: "md", lg: "lg" }}
            color="#fff"
            fontWeight="bold"
            mb="6px"
            my="6px"
          >
            <Text as="span" color="red.400">
              ↓{" "}
              {rates.day.average_buying
                ? rates.day.average_buying.toFixed(2)
                : "0.0"}
            </Text>
            {" / "}
            <Text as="span" color="green.400">
              ↑{" "}
              {rates.day.average_selling
                ? rates.day.average_selling.toFixed(2)
                : "0.0"}
            </Text>
          </Text>
        </Skeleton>
      </Flex>

      {/* Week */}
      <Flex direction="column" mr="20px">
        <Flex alignItems="center">
          {isLoading ? (
            <SkeletonCircle size="30px" me="6px" />
          ) : (
            <IconBox as="box" h={"30px"} w={"30px"} bg="brand.200" me="6px">
              <WalletIcon h={"15px"} w={"15px"} color="#fff" />
            </IconBox>
          )}
          <Skeleton isLoaded={!isLoading} w="50px">
            <Text fontSize="sm" color="gray.400">
              Week
            </Text>
          </Skeleton>
        </Flex>
        <Skeleton isLoaded={!isLoading}>
          <Text
            fontSize={{ sm: "md", lg: "lg" }}
            color="#fff"
            fontWeight="bold"
            mb="6px"
            my="6px"
          >
            <Text as="span" color="red.400">
              ↓{" "}
              {rates.week.average_buying
                ? rates.week.average_buying.toFixed(2)
                : "0.0"}
            </Text>
            {" / "}
            <Text as="span" color="green.400">
              ↑{" "}
              {rates.week.average_selling
                ? rates.week.average_selling.toFixed(2)
                : "0.0"}
            </Text>
          </Text>
        </Skeleton>
      </Flex>

      {/* Month */}
      <Flex direction="column">
        <Flex alignItems="center">
          {isLoading ? (
            <SkeletonCircle size="30px" me="6px" />
          ) : (
            <IconBox as="box" h={"30px"} w={"30px"} bg="brand.200" me="6px">
              <WalletIcon h={"15px"} w={"15px"} color="#fff" />
            </IconBox>
          )}
          <Skeleton isLoaded={!isLoading} w="50px">
            <Text fontSize="sm" color="gray.400">
              Month
            </Text>
          </Skeleton>
        </Flex>
        <Skeleton isLoaded={!isLoading}>
          <Text
            fontSize={{ sm: "md", lg: "lg" }}
            color="#fff"
            fontWeight="bold"
            mb="6px"
            my="6px"
          >
            <Text as="span" color="red.400">
              ↓{" "}
              {rates.month.average_buying
                ? rates.month.average_buying.toFixed(2)
                : "0.0"}
            </Text>
            {" / "}
            <Text as="span" color="green.400">
              ↑{" "}
              {rates.month.average_selling
                ? rates.month.average_selling.toFixed(2)
                : "0.0"}
            </Text>
          </Text>
        </Skeleton>
      </Flex>
    </SimpleGrid>
  );
}

export default RatesGrid;
