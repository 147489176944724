import React, { useState, useEffect } from "react";
import { Flex, Text, Image, Skeleton, Alert, AlertIcon } from "@chakra-ui/react";
import baseUrl from "utils/constants";

function TimelineRow({ bankLogos, currencyCodes, onTimestampSelect }) {
  const [lowestSellingRates, setLowestSellingRates] = useState([]);
  const [highestBuyingRates, setHighestBuyingRates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) {
      return "";
    }

    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      return "";
    }

    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Africa/Nairobi", // East Africa Time (EAT)
      hour12: true, // 24-hour format
    };

    const dateOptions = {
      weekday: "short",
      day: "2-digit",
      month: "long",
      year: "numeric",
    };

    const timeFormatter = new Intl.DateTimeFormat("en-US", timeOptions);
    const dateFormatter = new Intl.DateTimeFormat("en-US", dateOptions);

    const formattedTime = timeFormatter.format(date);
    const formattedDate = dateFormatter.format(date);

    return `${formattedTime} EAT, ${formattedDate}`; 
  };

  useEffect(() => {
    // Fetch data from your API
    fetch(`${baseUrl}/top_currency_rates`) 
      .then((response) => response.json())
      .then((data) => {
        const { lowest_selling_rates, highest_buying_rates } = data;

        if ((!lowest_selling_rates || lowest_selling_rates.length === 0) && (!highest_buying_rates || highest_buying_rates.length === 0)) {
          setError("No data available for the selected time range.");
        } else {
          setLowestSellingRates(lowest_selling_rates);
          setHighestBuyingRates(highest_buying_rates);

          let timestamp;
          if (highest_buying_rates && highest_buying_rates.length > 0) {
            timestamp = highest_buying_rates[0].timestamp;
          } else if (lowest_selling_rates && lowest_selling_rates.length > 0) {
            timestamp = lowest_selling_rates[0].timestamp;
          }

          if (timestamp) {
            const formattedTimestamp = formatTimestamp(timestamp);
            onTimestampSelect(formattedTimestamp);
          }
        }

        setIsLoading(false); // Data has loaded
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try again later.");
        setIsLoading(false);
      });
  }, [onTimestampSelect]);

  if (isLoading) {
    return (
      <Flex direction="column" lineHeight="21px">
        <Skeleton height="78px" borderRadius="md" />
        <Skeleton height="78px" borderRadius="md" mt="10px" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Alert status="error" variant="solid">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  return (
    <Flex direction="column" lineHeight="21px">
      {highestBuyingRates.length > 0 && (
        <>
          <Text
            fontSize="md"
            color="green.400"
            fontWeight="bold"
            mt="20px"
            mb="10px"
          >
            Highest Buying Prices
          </Text>
          {highestBuyingRates.map((row, index) => {
            const currencyData = currencyCodes[row.currency] || {}; // Get currency logo and name
            const { logo, name } = currencyData;

            return (
              <Skeleton
                key={index}
                isLoaded={!isLoading}
                h="78px"
                borderRadius="md"
              >
                <Flex
                  alignItems="center"
                  minH="78px"
                  justifyContent="start"
                  mb="5px"
                >
                  <Flex
                    direction="column"
                    h="100%"
                    align="center"
                    justify="center"
                    minWidth="30px"
                    mt="4px"
                  >
                    <Image
                      src={bankLogos[row.bank_name]}
                      alt={row.bank_name}
                      h={"20px"}
                      w={"20px"}
                      mb="2px" // Add margin-bottom for spacing between logo and text
                      onError={(e) => console.log("error", e)}
                    />
                    <Text
                      fontSize="7px"
                      color="#fff"
                      fontWeight="normal"
                      textAlign="center"
                    >
                      {row.bank_name}
                    </Text>
                  </Flex>

                  <Flex
                    direction="column"
                    justifyContent="flex-start"
                    h="100%"
                    ml="15px"
                  >
                    <Flex alignItems="center">
                      <Text
                        fontSize="sm"
                        color="#fff"
                        fontWeight="normal"
                        mb="3px"
                      >
                        {name} ({row.currency})
                      </Text>
                    </Flex>
                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                      {row.buying}
                    </Text>
                  </Flex>
                </Flex>
              </Skeleton>
            );
          })}
        </>
      )}

      {lowestSellingRates.length > 0 && (
        <>
          <Text
            fontSize="md"
            color="green.400"
            fontWeight="bold"
            mb="10px"
            mt="16px"
          >
            Lowest Selling Prices
          </Text>
          {lowestSellingRates.map((row, index) => {
            const currencyData = currencyCodes[row.currency] || {}; // Get currency logo and name
            const { logo, name } = currencyData;

            return (
              <Skeleton
                key={index}
                isLoaded={!isLoading}
                h="78px"
                borderRadius="md"
              >
                <Flex
                  alignItems="center"
                  minH="78px"
                  justifyContent="start"
                  mb="5px"
                >
                  <Flex
                    direction="column"
                    h="100%"
                    align="center"
                    justify="center"
                    minWidth="30px"
                    mt="4px"
                  >
                    <Image
                      src={bankLogos[row.bank_name]}
                      alt={row.bank_name}
                      h={"20px"}
                      w={"20px"}
                      mb="2px"
                      onError={(e) => console.log("error", e)}
                    />
                    <Text
                      fontSize="7px"
                      color="#fff"
                      fontWeight="normal"
                      textAlign="center"
                    >
                      {row.bank_name}
                    </Text>
                  </Flex>

                  <Flex
                    direction="column"
                    justifyContent="flex-start"
                    h="100%"
                    ml="15px"
                  >
                    <Flex alignItems="center">
                      <Text
                        fontSize="sm"
                        color="#fff"
                        fontWeight="normal"
                        mb="3px"
                      >
                        {name} ({row.currency})
                      </Text>
                    </Flex>
                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                      {row.selling}
                    </Text>
                  </Flex>
                </Flex>
              </Skeleton>
            );
          })}
        </>
      )}
    </Flex>
  );
}

export default TimelineRow;
