import React, { useState, useEffect } from 'react';
import { Flex, Skeleton, Alert, AlertIcon, Text } from '@chakra-ui/react';
import { marked } from 'marked'; // Import the marked library
import baseUrl from 'utils/constants';

const formatTimestamp = (timestamp) => {
  if (!timestamp) {
    return "";
  }

  const date = new Date(timestamp);

  if (isNaN(date.getTime())) {
    return "";
  }

  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Africa/Nairobi", // East Africa Time (EAT)
    hour12: true, // 24-hour format
  };

  const dateOptions = {
    weekday: "short",
    day: "2-digit",
    month: "long",
    year: "numeric",
  };

  const timeFormatter = new Intl.DateTimeFormat("en-US", timeOptions);
  const dateFormatter = new Intl.DateTimeFormat("en-US", dateOptions);

  const formattedTime = timeFormatter.format(date);
  const formattedDate = dateFormatter.format(date);

  return `${formattedTime} ${formattedDate} (EAT)`; 
};

function FormattedContentDisplay({documentName, documentTitle}) {
  const [formattedContent, setFormattedContent] = useState('');
  const [loadedDate, setLoadedDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the latest formatted content from the backend with the specified document name
    fetch(`${baseUrl}/latest_analysis?document_name=${encodeURIComponent(documentName)}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.content) {
          // Convert Markdown to HTML using marked
          const htmlContent = marked(data['content']);
          setLoadedDate(formatTimestamp(data['timestamp']));
          setFormattedContent(htmlContent);
        } else {
          setError('No content available.');
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching formatted content:', error);
        setError('Failed to load content. Please try again later.');
        setIsLoading(false);
      });
  }, [documentName]);

  if (isLoading) {
    return (
      <Flex direction="column" lineHeight="21px">
        <Skeleton height="78px" borderRadius="md" />
        <Skeleton height="78px" borderRadius="md" mt="10px" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Alert status="error" variant="solid">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  return (
    <Flex direction="column" lineHeight="34px" color="#fff">
      <Text fontSize="28px" color="#fff" fontWeight="bold" mb="18px">
       {documentTitle}
      </Text>
      <Text fontSize="21px" color="#fff" fontWeight="bold">
        The analysis is based on the data observed in the past month, week and today until {loadedDate}
      </Text>
      <div>
        <style>
          {`
            .formatted-content {
              color: white;
              text-align: justify;
              transition: all 0.3s ease;
            }

            .formatted-content strong {
              font-size: 1.2em; /* Adjust this to make <strong> elements bigger */
            }
          `}
        </style>
        <div
          className="formatted-content"
          dangerouslySetInnerHTML={{ __html: formattedContent }}
        />
      </div>
    </Flex>
  );
}

export default FormattedContentDisplay;
