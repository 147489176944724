import { extendTheme } from "@chakra-ui/react";
import { globalStyles } from "./styles";
import { bgAdmin } from "./bgAdmin";
import { breakpoints } from "./foundations/breakpoints";
import { CardComponent } from "./additions/card/Card";
import { CardBodyComponent } from "./additions/card/CardBody";
import { CardHeaderComponent } from "./additions/card/CardHeader";
import { MainPanelComponent } from "./additions/layout/MainPanel";
import { PanelContentComponent } from "./additions/layout/PanelContent";
import { PanelContainerComponent } from "./additions/layout/PanelContainer";
export default extendTheme(
  { breakpoints }, // Breakpoints
  bgAdmin, // Global styles
  globalStyles, // Global styles
  CardComponent, // Card component
  CardBodyComponent, // Card Body component
  CardHeaderComponent, // Card Header component
  MainPanelComponent, // Main Panel component
  PanelContentComponent, // Panel Content component
  PanelContainerComponent // Panel Container component
);
