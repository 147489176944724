import Dashboard from "views/Dashboard/Dashboard.js";
import {
  HomeIcon,
 } from "components/Icons/Icons";
import PrivacyPolicy from "views/PrivacyPolicy/PrivacyPolicy";

var dashRoutes = [
  {
    path: "/",
    name: "Dashboard",
    icon: <HomeIcon color='inherit' />,
    component: Dashboard,
    layout: "/main",
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    icon: <HomeIcon color='inherit' />,
    component: PrivacyPolicy,
    layout: "/main",
  },
 
 
];
export default dashRoutes;
