import React, { useState } from 'react';
import { Box, Flex, Button, IconButton, useMediaQuery } from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const history = useHistory();
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (sectionId) => {
    const executeScroll = () => {
      const element = document.getElementById(sectionId);
      if (element) {
        const offset = element.getBoundingClientRect().top + window.scrollY; // Get the exact top position
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }
    };
  
    // Check if the user is on the home page; if not, redirect to home first
    if (location.pathname !== '/main') {
      history.push('/main');
      setTimeout(executeScroll, 200); // Adjust delay if needed
    } else {
      executeScroll();
    }
    setIsOpen(false); // Close the menu after clicking
  };
  
  
  

  return (
    <Box bg="#161a33" px="4" py="2" position="fixed" top="0" width="100%" zIndex="1000">
      <Flex alignItems="center" justifyContent="space-between">
        <Box color="white" fontSize="lg" fontWeight="bold">
          Birr Value
        </Box>

        {isLargerThan768 ? (
          <Flex ml="auto">
            <Button variant="link" color="white" mx="3" onClick={() => scrollToSection('welcome-section')}>
              Welcome
            </Button>
            <Button variant="link" color="white" mx="3" onClick={() => scrollToSection('currency-converter-section')}>
              Currency Converter
            </Button>
            <Button variant="link" color="white" mx="3" onClick={() => scrollToSection('data-comparison')}>
              Exchange Data
            </Button>
            <Button variant="link" color="white" mx="3" onClick={() => scrollToSection('top-exchanges')}>
              Top Exchanges
            </Button>
            <Button variant="link" color="white" mx="3" onClick={() => scrollToSection('market-analysis-section')}>
              AI Market Analysis
            </Button>
            <Button variant="link" color="white" mx="3" onClick={() => scrollToSection('bank-analysis-section')}>
              AI Bank Analysis
            </Button>
            <Button variant="link" color="white" mx="3" onClick={() => window.location.href = '/faq'}>
              FAQ
            </Button>
          </Flex>
        ) : (
          <IconButton
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label="Toggle navigation"
            color="white"
            onClick={toggleMenu}
            bg="transparent"
            _hover={{ bg: 'transparent' }}
          />
        )}
      </Flex>

      {!isLargerThan768 && isOpen && (
        <Flex direction="column" bg="#161a33" p="4" mt="2" rounded="md">
          <Button variant="link" color="white" mb="2" onClick={() => scrollToSection('welcome-section')}>
            Welcome
          </Button>
          <Button variant="link" color="white" mb="2" onClick={() => scrollToSection('currency-converter-section')}>
            Currency Converter
          </Button>
          <Button variant="link" color="white" mb="2" onClick={() => scrollToSection('data-comparison')}>
            Exchange Data
          </Button>
          <Button variant="link" color="white" mb="2" onClick={() => scrollToSection('top-exchanges')}>
            Top Exchanges
          </Button>
          <Button variant="link" color="white" mb="2" onClick={() => scrollToSection('market-analysis-section')}>
            AI Market Analysis
          </Button>
          <Button variant="link" color="white" mb="2" onClick={() => scrollToSection('bank-analysis-section')}>
            AI Bank Analysis
          </Button>
          <Button variant="link" color="white" mx="3" onClick={() => window.location.href = '/faq'}>
              FAQ
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default Navbar;
