import React from 'react';
import Card from 'components/Card/Card';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import FormattedContentDisplay from './FormattedContentDisplay'; // Ensure this import is correct

const BankAnalysis = () => {
  return (
    <>
      <Helmet>
        <title>AI Bank Analysis | Forex Market Trends & Bank Performance | Birr Value</title>
        <meta
          name="description"
          content="Discover AI-driven insights on how Ethiopian banks are performing in the forex market. Get in-depth analysis of market trends and bank performance to make informed decisions."
        />
        <meta
          name="keywords"
          content="AI bank analysis, Ethiopian bank performance, forex market trends, CBE analysis, Awash Bank performance, forex insights, AI-driven market analysis, best performing banks Ethiopia"
        />
        <meta name="author" content="Birr Value" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://birrvalue.com/bank-analysis" />
        <meta
          property="og:title"
          content="AI Bank Analysis | Forex Market Trends & Bank Performance | Birr Value"
        />
        <meta
          property="og:description"
          content="Explore AI-driven analysis of Ethiopian banks' performance in the forex market. See how CBE, Awash, and other banks compare based on market trends."
        />
        <meta property="og:image" content="https://birrvalue.com/logo.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://birrvalue.com/bank-analysis" />
        <meta
          property="twitter:title"
          content="AI Bank Analysis | Forex Market Trends & Bank Performance | Birr Value"
        />
        <meta
          property="twitter:description"
          content="Access AI-powered insights on Ethiopian banks' forex performance. Compare market trends and find out which banks are excelling."
        />
        <meta property="twitter:image" content="https://birrvalue.com/logo.png" />

        {/* Structured Data for SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FinancialService",
            "name": "AI Bank Analysis",
            "url": "https://birrvalue.com/bank-analysis",
            "description":
              "Explore AI-driven insights on how Ethiopian banks perform in the forex market based on current market trends.",
            "serviceType": "Financial Analysis",
            "offers": {
              "@type": "Offer",
              "priceCurrency": "ETB",
              "eligibleRegion": {
                "@type": "Country",
                "name": "Ethiopia",
              },
            },
          })}
        </script>
      </Helmet>

      <Box p="4" pt={{ base: '80px', md: '100px' }} color="#fff">
        <Card
          p="10px"
          mb="26px"
          mt="24px"
          bg="#1a1f3d"
          position="relative"
          overflow="hidden"
        >
          <Box position="relative" zIndex="2" p="16px">
            <Flex flexDirection={{ sm: 'column', lg: 'row' }} w="100%" h="100%">
              <Flex flexDirection="column" h="100%" p="22px" minW="60%" lineHeight="1.6">
                {/* Fetching and displaying the AI analysis content */}
                <FormattedContentDisplay
                  documentName="bank_analysis"
                  documentTitle="AI Driven Bank Analysis"
                />
              </Flex>
            </Flex>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default BankAnalysis;
