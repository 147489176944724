import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import PrivacyPolicy from "views/PrivacyPolicy/PrivacyPolicy.js";
import CurrencyConverterPage from "components/Tables/CurrencyConverterPage";
import BankAnalysis from "components/Tables/BankAnalysisPage";
import MarketAnalysis from "components/Tables/MakretAnalysisPage";
import FAQPage from "components/Tables/FaqPage";

const FaviconRoute = () => {
  window.location.href = '/favicon.ico';
  return null;
};
const LogoRoute = () => {
  window.location.href = '/logo.png';
  return null;
};

ReactDOM.render(
  <BrowserRouter>
    <Switch>
    <Route path={`/`} component={AdminLayout} />
    <Route path={`/privacy-policy`} component={PrivacyPolicy} />
    <Route exact path="/currency-converter" component={CurrencyConverterPage} />
    <Route exact path="/bank-analysis" component={BankAnalysis} />
    <Route exact path="/market-analysis" component={MarketAnalysis} />
    <Route exact path="/faq" component={FAQPage} />
    <Route exact path="/favicon.ico" component={FaviconRoute} /> {/* Route to serve favicon */}
    <Route exact path="/logo.png" component={LogoRoute} /> {/* Route to serve logo */}
     <Redirect from={`*`} to='/' />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
