import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Spinner, Box } from "@chakra-ui/react";
import baseUrl from "utils/constants";

const LineChart = ({ bankName, currency, timeRange }) => {
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "12px",
        },
        format: "dd MMM", // Default format
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "12px",
        },
        formatter: (value) => value.toFixed(2),
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
      borderColor: "#56577A",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors: ["#2CD9FF", "#582CFF"],
    },
    colors: ["#2CD9FF", "#582CFF"],
  });
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    if (bankName && currency && timeRange) {
      setIsLoading(true); // Set loading to true when fetching data
      fetch(
        `${baseUrl}/exchange_rate_graph_data?bank_name=${bankName}&currency=${currency}&time_range=${timeRange}`
      )
        .then((response) => response.json())
        .then((data) => {
          const formattedData = [
            {
              name: "Buying",
              data: data.data.map((point) => ({
                x: new Date(point.timestamp).getTime(),
                y: parseFloat(point.buying),
              })),
            },
            {
              name: "Selling",
              data: data.data.map((point) => ({
                x: new Date(point.timestamp).getTime(),
                y: parseFloat(point.selling),
              })),
            },
          ];

          let xaxisFormat = "dd MMM"; // Default format for the week
          if (timeRange === "day") {
            xaxisFormat = "HH:mm"; // Hourly format for the day
          } else if (timeRange === "month") {
            xaxisFormat = "dd MMM"; // Daily format for the month
          }

          setChartOptions((prevOptions) => ({
            ...prevOptions,
            xaxis: {
              ...prevOptions.xaxis,
              labels: {
                ...prevOptions.xaxis.labels,
                format: xaxisFormat,
              },
            },
          }));

          setChartData(formattedData);
          setIsLoading(false); // Set loading to false after data is fetched
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false); // Set loading to false even if there is an error
        });
    }
  }, [bankName, currency, timeRange]);

  return (
    <Box position="relative" width="100%" height="100%">
      <ReactApexChart
        options={chartOptions}
        series={chartData}
        type="area"
        width="100%"
        height="100%"
      />
      {isLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          pointerEvents="none" // Prevents the overlay from blocking interactions
        >
          <Spinner size="xl" color="blue.500" />
        </Box>
      )}
    </Box>
  );
};

export default LineChart;
