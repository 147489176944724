import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  SimpleGrid,
  Spacer,
  Text,
  Select,
} from "@chakra-ui/react";
import medusa from "assets/img/cardimgfree.png";
import theme from "theme/themeAdmin.js";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import TimelineRow from "components/Tables/TimelineRow";
import React, { useState, useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import CurrencyRatesTable from "components/Tables/DashboardTableRow";
import FormattedContentDisplay from "components/Tables/FormattedContentDisplay";
import CurrencyConverter from "components/Tables/CurrencyConverterForm";
import oromiaImg from "assets/img/banks/oromia.png";
import abayImg from "assets/img/banks/abay.png";
import addisImg from "assets/img/banks/addis.png";
import amharaImg from "assets/img/banks/amhara.png";
import ahaduImg from "assets/img/banks/ahadu.png";
import siinqeeImg from "assets/img/banks/siinqee.png";
import wegagenImg from "assets/img/banks/wegagen.png";
import abssinyaImg from "assets/img/banks/abssinya.png";
import hibretImg from "assets/img/banks/hbret.png";
import brhanImg from "assets/img/banks/brhan.png";
import bunaImg from "assets/img/banks/buna.jpeg";
import enatImg from "assets/img/banks/enat.png";
import nibImg from "assets/img/banks/nib.png";
import zemenImg from "assets/img/banks/zemen.png";
import coopImg from "assets/img/banks/coop.png";
import cbeImg from "assets/img/banks/cbe.png";
import awashImg from "assets/img/banks/awash.png";
import baseUrl from "utils/constants";
import RatesGrid from "components/Tables/ratesGrid";

export default function Dashboard() {
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState("Awash");
  const [selectedBankForTable, setSelectedBankForTable] = useState("Awash");
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [selectedTimeRange, setSelectedTimeRange] = useState("day");

  const [selectedTimeStamp, setSelectedTimeStamp] = useState("");

  const [
    selectedComparativeCurrency,
    setSelectedComparativeCurrency,
  ] = useState("USD");
  const [
    selectedComparativeTimeRange,
    setSelectedComparativeTimeRange,
  ] = useState("day");

  const [rates, setRates] = useState({
    day: { average_buying: 0, average_selling: 0 },
    week: { average_buying: 0, average_selling: 0 },
    month: { average_buying: 0, average_selling: 0 },
  });

  const bankLogos = {
    Oromia: oromiaImg,
    CBE: cbeImg,
    Awash: awashImg,
    Abyssinia: abssinyaImg,
    Hibret: hibretImg,
    Berhan: brhanImg,
    Buna: bunaImg,
    Enat: enatImg,
    Nib: nibImg,
    Zemen: zemenImg,
    COOP: coopImg,
    Ahadu: ahaduImg,
    Addis: addisImg,
    Abay: abayImg,
    Amhara: amharaImg,
    Siinqee: siinqeeImg,
    WEGAGEN: wegagenImg,
  };

  const currencyLogos = {
    USD: {
      logo: "https://flagsapi.com/US/flat/64.png",
      name: "US Dollar",
    },
    GBP: {
      logo: "https://flagsapi.com/GB/flat/64.png",
      name: "Pound Sterling",
    },
    EUR: {
      logo: "https://flagcdn.com/h24/eu.png",
      name: "Euro",
    },
    CHF: {
      logo: "https://flagsapi.com/CH/flat/64.png",
      name: "Swiss Franc",
    },
    SEK: {
      logo: "https://flagsapi.com/SE/flat/64.png",
      name: "Swedish Krona",
    },
    NOK: {
      logo: "https://flagsapi.com/NO/flat/64.png",
      name: "Norwegian Krone",
    },
    DKK: {
      logo: "https://flagsapi.com/DK/flat/64.png",
      name: "Danish Krone",
    },
    JPY: {
      logo: "https://flagsapi.com/JP/flat/64.png",
      name: "Japanese Yen",
    },
    CAD: {
      logo: "https://flagsapi.com/CA/flat/64.png",
      name: "Canadian Dollar",
    },
    SAR: {
      logo: "https://flagsapi.com/SA/flat/64.png",
      name: "Saudi Riyal",
    },
    AED: {
      logo: "https://flagsapi.com/AE/flat/64.png",
      name: "UAE Dirham",
    },
    AUD: {
      logo: "https://flagsapi.com/AU/flat/64.png",
      name: "Australian Dollar",
    },
    CNY: {
      logo: "https://flagsapi.com/CN/flat/64.png",
      name: "Chinese Yuan",
    },
    KWD: {
      logo: "https://flagsapi.com/KW/flat/64.png",
      name: "Kuwaiti Dinar",
    },
    DJF: {
      logo: "https://flagsapi.com/DJ/flat/64.png",
      name: "Djiboutian Franc",
    },
    INR: {
      logo: "https://flagsapi.com/IN/flat/64.png",
      name: "Indian Rupee",
    },
    KES: {
      logo: "https://flagsapi.com/KE/flat/64.png",
      name: "Kenyan Shilling",
    },
    ZAR: {
      logo: "https://flagsapi.com/ZA/flat/64.png",
      name: "South African Rand",
    },
    YUA: {
      logo: "https://flagsapi.com/CN/flat/64.png",
      name: "Chinese Yuan",
    },
  };

  const [currencyCodes, setCurrencyCodes] = useState([]);

  useEffect(() => {
    // Fetch list of banks from your API
    fetch(`${baseUrl}/banks`)
      .then((response) => response.json())
      .then((data) => {
        setBanks(data.banks);
        setSelectedBank(data.banks[0]);
        setSelectedBankForTable(data.banks[0]); // Set the first bank as default
      })
      .catch((error) => console.error("Error fetching banks:", error));
  }, []);

  useEffect(() => {
    if (selectedBank) {
      // Fetch currency codes from the API when a bank is selected
      fetch(`${baseUrl}/currency_codes?bank_name=${selectedBank}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.currency_codes) {
            setCurrencyCodes(data.currency_codes);
          } else {
            setCurrencyCodes([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching currency codes:", error);
          setCurrencyCodes([]);
        });
    } else {
      setCurrencyCodes([]);
    }
  }, [selectedBank]);

  const handleTimestampSelect = (formattedTimestamp) => {
    setSelectedTimeStamp(formattedTimestamp);
  };

  return (
    <div>
      <Flex flexDirection="column" pt={{ base: "80px", md: "45px" }}>
        {/* Welcome Card */}
        <Card
          id="welcome-section"
          p="0px"
          mb="26px"
          bgImage={medusa}
          bgSize="cover"
          bgPosition="50%"
          position="relative" // Make the card a positioned element
          overflow="hidden" // Ensure the shadow stays within the card boundaries
        >
          {/* Add a shadow overlay */}
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="rgba(0, 0, 0, 0.5)" // Dark shadow with transparency
            zIndex="1" // Ensure the shadow appears above the background
          />

          {/* Content of the card goes here */}
          <Box position="relative" zIndex="2" p="16px">
            <Flex flexDirection={{ sm: "column", lg: "row" }} w="100%" h="100%">
              <Flex
                flexDirection="column"
                h="100%"
                p="22px"
                minW="60%"
                lineHeight="1.6"
              >
                <Text fontSize="28px" color="#fff" fontWeight="bold" mb="18px">
                Birr Value
                </Text>
                <Text
                  fontSize="md"
                  color="#fff"
                  fontWeight="normal"
                  mb="auto"
                >
                  Maximizing Your Value with Ethiopia’s Best Currency Exchange Rates
                  <br />
                  At Birr Value, we provide up-to-date, accurate, and user-friendly data on currency exchange rates across major Ethiopian banks. Our AI-powered market and bank analysis helps you understand forex trends, making it easier for importers, exporters, and traders to make informed decisions. Whether you are sending money to family, trading, or traveling, Birr Value highlights the best rates in real-time, ensuring you get the most value for your transactions. Stay ahead with Birr Value, your trusted source for reliable forex insights and comparisons in Ethiopia.
                  </Text>
                <Spacer />
                <Flex align="center" mt="4">
                    <Text
                      fontSize="sm"
                      color="#fff"
                      fontWeight="bold"
                      transition="all .3s ease"
                      my={{ sm: "1.5rem", lg: "0px" }}
                      _hover={{ me: "4px" }}
                    >
                      {selectedTimeStamp && (
                        <Text color="white" fontSize="sm" mb="16px">
                          Latest Update: {selectedTimeStamp}
                        </Text>
                      )}
                    </Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Card>

        <Card
          id="currency-converter-section"
          p={{ base: "5px", md: "10px" }} // Adjust padding based on screen size
          mb={{ base: "16px", md: "26px" }} // Reduced margin bottom for smaller screens
          mt={{ base: "16px", md: "24px" }} // Reduced margin top for smaller screens
          bg="#1a1f3d"
          position="relative" // Make the card a positioned element
          overflow="hidden" // Ensure the shadow stays within the card boundaries
        >
            <Flex flexDirection={{ base: "column", lg: "row" }} w="100%" h="100%">
              <Flex
                flexDirection="column"
                h="100%"
                p={{ base: "12px", md: "22px" }} // Adjust padding inside the Flex for responsiveness
                minW="60%"
                lineHeight="1.6"
              >
                <CurrencyConverter />
              </Flex>
            </Flex>
        </Card>

        <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px"></SimpleGrid>
        <Grid
          templateColumns={{ sm: "1fr", md: "1fr 1fr !important", xl: "1fr 1fr !important", "2xl": "2fr 2fr !important" }}
          gap="18px"
        >
        <Card p="28px 0px 0px 0px" id="data-comparison">
          <CardHeader mb="20px" ps="22px">
            <Flex direction="column" alignSelf="flex-start">
              <Flex alignItems="center" mb="6px">
                <Text fontSize="lg" color="#fff" fontWeight="bold">
                  Exchange Rate
                </Text>
                {/* Blinking Live Dot with Text, visible only when "Today" is selected */}
                {selectedTimeRange === 'day' && (
                  <Flex alignItems="center" ml="10px">
                    <Box
                      w="8px"
                      h="8px"
                      bg="red.500"
                      borderRadius="50%"
                      mr="5px"
                      animation="blink 1s infinite"
                    />
                    <Text fontSize="sm" color="red.500" fontWeight="bold">
                      LIVE
                    </Text>
                  </Flex>
                )}
              </Flex>
              {banks.length > 0 && (
                <Flex
                  gap={{ base: "10px", md: "20px" }}
                  mt="10px"
                  wrap="wrap"
                  justify={{ base: "center", md: "space-between" }}
                  direction={{ base: "column", md: "row" }}
                >
                  {/* Bank Selector */}
                  <Select
                    placeholder={selectedBank ? "" : "Select Bank"}
                    value={selectedBank}
                    onChange={(e) => setSelectedBank(e.target.value)}
                    bg="#130156"
                    color="white"
                    fontFamily="Plus Jakarta Display"
                    borderColor="#56577A"
                    _hover={{ borderColor: "#56577A" }}
                    _focus={{ borderColor: "#56577A" }}
                    mb="10px"
                    width="150px" // Adjusted width
                    sx={{
                      option: {
                        bg: "#0e0042",
                        color: "white",
                      },
                    }}
                  >
                    {banks
                      .sort((a, b) => a.localeCompare(b))
                      .map((bank, index) => (
                        <option key={index} value={bank}>
                          {bank}
                        </option>
                      ))}
                  </Select>

                  {/* Currency Selector */}
                  <Select
                    placeholder={selectedCurrency ? "" : "Select Currency"}
                    value={selectedCurrency}
                    onChange={(e) => setSelectedCurrency(e.target.value)}
                    bg="#130156"
                    color="white"
                    fontFamily="Plus Jakarta Display"
                    borderColor="#56577A"
                    _hover={{ borderColor: "#56577A" }}
                    _focus={{ borderColor: "#56577A" }}
                    mb="10px"
                    width="150px" // Adjusted width
                    sx={{
                      option: {
                        bg: "#0e0042",
                        color: "white",
                      },
                    }}
                  >
                    {currencyCodes.map((currency, index) => (
                      <option key={index} value={currency}>
                        {currency}
                      </option>
                    ))}
                  </Select>

                  {/* Date Range Selector */}
                  <Select
                    placeholder={selectedTimeRange ? "" : "Select Date Range"}
                    value={selectedTimeRange}
                    onChange={(e) => setSelectedTimeRange(e.target.value)}
                    bg="#130156"
                    color="white"
                    fontFamily="Plus Jakarta Display"
                    borderColor="#56577A"
                    _hover={{ borderColor: "#56577A" }}
                    _focus={{ borderColor: "#56577A" }}
                    mb="10px"
                    width="150px"
                    sx={{
                      option: {
                        bg: "#0e0042",
                        color: "white",
                      },
                    }}
                  >
                    <option value="day">Today</option>
                    <option value="week">This Week</option>
                    <option value="month">This Month</option>
                  </Select>
                </Flex>
              )}
            </Flex>
          </CardHeader>

          <Box w="100%" minH={{ sm: "300px" }}>
            <LineChart
              bankName={selectedBank}
              currency={selectedCurrency}
              timeRange={selectedTimeRange}
            />
          </Box>

          <Flex
            ps="10px"
            direction="column"
            mt="24px"
            mb="36px"
            alignSelf="flex-start"
          >
            <Text fontSize="lg" color="#fff" fontWeight="bold" mb="6px">
              {selectedBank}
            </Text>
            <Text fontSize="md" fontWeight="medium" color="gray.400">
              <Text as="span" color="green.400" fontWeight="bold">
                ({selectedCurrency})
              </Text>{" "}
              Avarage Buying and Selling Price per
            </Text>
          </Flex>

          <RatesGrid
            selectedBank={selectedBank}
            selectedCurrency={selectedCurrency}
          />
        </Card>

        {/* CSS for Blinking Animation */}
        <style>
          {`
            @keyframes blink {
              0% { opacity: 1; }
              50% { opacity: 0; }
              100% { opacity: 1; }
            }
          `}
        </style>


          <Card p="16px">
            <CardHeader mb="20px" ps="22px">
              <Flex direction="column" alignSelf="flex-start">
                <Text fontSize="lg" color="#fff" fontWeight="bold" mb="6px">
                  Comparision of Rates between banks
                </Text>

                {banks.length > 0 && (
                  <Flex gap="10px" mt="10px" wrap="wrap" justify="space-between">
                    {/* Currency Selector */}
                    <Select
                      placeholder={
                        selectedComparativeCurrency ? "" : "Select Currency"
                      }
                      value={selectedComparativeCurrency}
                      onChange={(e) =>
                        setSelectedComparativeCurrency(e.target.value)
                      }
                      bg="#130156"
                      color="white"
                      fontFamily="Plus Jakarta Display"
                      borderColor="#56577A"
                      _hover={{ borderColor: "#56577A" }}
                      _focus={{ borderColor: "#56577A" }}
                      mb="10px"
                      width="150px"
                      sx={{
                        option: {
                          bg: "#0e0042",
                          color: "white",
                        },
                      }}
                    >
                      <option value="USD">USD</option>
                      <option value="GBP">GBP</option>
                      <option value="EUR">EUR</option>
                      <option value="CHF">CHF</option>
                      <option value="SEK">SEK</option>
                      <option value="JPY">JPY</option>
                      <option value="CAD">CAD</option>
                      <option value="AED">AED</option>
                      <option value="CNY">CNY</option>
                    </Select>

                    {/* Date Range Selector */}
                    <Select
                      placeholder={
                        selectedComparativeTimeRange ? "" : "Select Date Range"
                      }
                      value={selectedComparativeTimeRange}
                      onChange={(e) =>
                        setSelectedComparativeTimeRange(e.target.value)
                      }
                      bg="#130156"
                      color="white"
                      fontFamily="Plus Jakarta Display"
                      borderColor="#56577A"
                      _hover={{ borderColor: "#56577A" }}
                      _focus={{ borderColor: "#56577A" }}
                      mb="10px"
                      width="150px" // Adjusted width
                      sx={{
                        option: {
                          bg: "#0e0042",
                          color: "white",
                        },
                      }}
                    >
                      <option value="day">Today</option>
                      <option value="week">This Week</option>
                      <option value="month">This Month</option>
                    </Select>
                  </Flex>
                )}
              </Flex>
            </CardHeader>

            <CardBody>
              <Flex direction="column" w="100%">
                <Box
                  bg="linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)"
                  borderRadius="20px"
                  display={{ sm: "flex", md: "block" }}
                  justify={{ sm: "center", md: "flex-start" }}
                  align={{ sm: "center", md: "flex-start" }}
                  minH={{ sm: "180px", md: "220px" }}
                  p={{ sm: "0px", md: "22px" }}
                >
                  <BarChart
                    currency={selectedComparativeCurrency}
                    timeRange={selectedComparativeTimeRange}
                  />
                </Box>
              </Flex>
            </CardBody>
          </Card>
        </Grid>

        <Grid
          templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr" }}
          gap="24px"
          mt="24px" 
        >
          {/* Projects */}
          <Card p="16px" overflowX={{ sm: "scroll", xl: "hidden" }}>
            <CardHeader p="12px 0px 28px 0px">
              {banks.length > 0 && (
                <Flex direction="column">
                  <Select
                    placeholder={selectedBankForTable ? "" : "Select Bank"}
                    value={selectedBankForTable}
                    onChange={(e) => setSelectedBankForTable(e.target.value)}
                    bg="#130156"
                    color="white"
                    fontFamily="Plus Jakarta Display"
                    borderColor="#56577A"
                    _hover={{ borderColor: "#56577A" }}
                    _focus={{ borderColor: "#56577A" }}
                    mb="24px"
                    sx={{
                      option: {
                        bg: "#0e0042",
                        color: "white",
                      },
                    }}
                  >
                    {banks.map((bank, index) => (
                      <option key={index} value={bank}>
                        {bank}
                      </option>
                    ))}
                  </Select>
                </Flex>
              )}
            </CardHeader>

            {selectedBankForTable && (
              <CurrencyRatesTable
                selectedBank={selectedBankForTable}
                currencyLogos={currencyLogos}
              />
            )}
          </Card>

          <Card id="top-exchanges">
            <CardHeader mb="20px">
              <Flex direction="column">
                <Text fontSize="lg" color="#fff" fontWeight="bold" mb="6px">
                  Top Exchanges
                </Text>
                <Flex align="center">
                  <Icon
                    as={AiFillCheckCircle}
                    color="green.500"
                    w="15px"
                    h="15px"
                    me="5px"
                  />
                  <Text fontSize="sm" color="gray.400" fontWeight="normal">
                    <Text fontWeight="bold" as="span" color="gray.400">
                      These are the top currencies
                    </Text>
                    {"  "}
                    offered by different banks at the highest buying and lowest
                    selling price.
                  </Text>
                </Flex>
              </Flex>
            </CardHeader>
            <CardBody>
              <Flex direction="column" lineHeight="21px">
                <TimelineRow
                  bankLogos={bankLogos}
                  currencyCodes={currencyLogos}
                  onTimestampSelect={handleTimestampSelect}
                />
              </Flex>
            </CardBody>
          </Card>
        </Grid>

        <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px"></SimpleGrid>
        <Grid
          templateColumns={{ sm: "1fr", md: "1fr 1fr !important", xl: "1fr 1fr !important", "2xl": "2fr 2fr !important" }}
          gap="18px"
        >
        <Card
          id="market-analysis-section"
          p="10px"
          mb="26px"
          mt="24px"
          bg= "#1a1f3d"
          position="relative" // Make the card a positioned element
          overflow="hidden" // Ensure the shadow stays within the card boundaries
        >
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            zIndex="1" // Ensure the shadow appears above the background
          />
          <Box position="relative" zIndex="2" p="16px" >
            <Flex flexDirection={{ sm: "column", lg: "row" }} w="100%" h="100%">
              <Flex
                flexDirection="column"
                h="100%"
                p="22px"
                minW="60%"
                lineHeight="1.6"
              >
                <FormattedContentDisplay 
                      documentName={"market_analysis"}
                      documentTitle={"AI Driven Market Analysis"}
                    />
              </Flex>
            </Flex>
          </Box>
        </Card>

        <Card
          id="bank-analysis-section"
          p="10px"
          mb="26px"
          mt="24px"
          bg= "#1a1f3d"
          position="relative" // Make the card a positioned element
          overflow="hidden" // Ensure the shadow stays within the card boundaries
        >
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            zIndex="1" // Ensure the shadow appears above the background
          />
          <Box position="relative" zIndex="2" p="16px" >
            <Flex flexDirection={{ sm: "column", lg: "row" }} w="100%" h="100%">
              <Flex
                flexDirection="column"
                h="100%"
                p="22px"
                minW="60%"
                lineHeight="1.6"
              >
                <FormattedContentDisplay 
                      documentName={"bank_analysis"}
                      documentTitle={"AI Driven Bank Analysis"}
                    />
              </Flex>
            </Flex>
          </Box>
        </Card>

        </Grid>

      </Flex>
    </div>
  );
}
