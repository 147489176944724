import React from 'react';
import { Box, Flex, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

const FAQPage = () => {
  const faqData = [
    {
      question: "What is Birr Value?",
      answer:
        "Birr Value is a platform that provides real-time currency exchange rates from major Ethiopian banks. We also offer AI-driven market analysis to help you make informed decisions when buying or selling foreign currencies.",
    },
    {
      question: "Which banks are included in Birr Value’s exchange rate comparisons?",
      answer:
        "Birr Value includes exchange rates from major Ethiopian banks such as Commercial Bank of Ethiopia (CBE), Awash Bank, Enat Bank, Dashen Bank, and more, providing a comprehensive view of the market.",
    },
    {
      question: "How frequently is the exchange rate data updated?",
      answer:
        "The exchange rate data on Birr Value is updated every 15 minutes to ensure you have access to the most current rates available from participating banks.",
    },
    {
      question: "What currencies are available for comparison on Birr Value?",
      answer:
        "Birr Value provides exchange rate comparisons for popular currencies, including the US Dollar (USD), Euro (EUR), Pound Sterling (GBP), Canadian Dollar (CAD), Chinese Yuan (CNY), and more.",
    },
    {
      question: "Can I convert currencies using Birr Value?",
      answer:
        "Yes, you can use our Currency Converter tool to calculate how much Ethiopian Birr (ETB) you will receive or need to pay based on the latest buying and selling rates from different banks.",
    },
    {
      question: "What is AI-driven market analysis on Birr Value?",
      answer:
        "Our AI-driven market analysis provides insights into how Ethiopian banks are performing in the forex market based on recent trends and data, helping you make more informed financial decisions.",
    },
    {
      question: "Why should I trust Birr Value’s exchange rates?",
      answer:
        "Birr Value sources exchange rates directly from official bank websites and updates the data frequently. This ensures that you receive accurate and reliable information to compare rates across different banks.",
    },
    {
      question: "How can I find the best exchange rate for my transaction?",
      answer:
        "Use our platform to compare buying and selling rates from various banks. The platform highlights the best rates, making it easy to identify which bank offers the most favorable rates for your needs.",
    },
    {
      question: "Is Birr Value free to use?",
      answer:
        "Yes, Birr Value is completely free to use. Our mission is to provide you with accurate, real-time exchange rate information and market analysis without any cost.",
    },
    {
      question: "Can I access Birr Value on mobile devices?",
      answer:
        "Yes, Birr Value is optimized for both desktop and mobile devices, allowing you to access real-time exchange rates and market analysis on the go.",
    },
    {
      question: "Does Birr Value offer historical exchange rate data?",
      answer:
        "Yes, Birr Value provides historical data for currency rates, allowing you to analyze trends over time and make more informed decisions based on past performance.",
    },
    {
      question: "How can I stay updated with the latest exchange rates?",
      answer:
        "You can visit Birr Value regularly to check the latest exchange rates. Additionally, you can bookmark our Currency Converter page or subscribe to our updates to get the latest rates directly.",
    },
    {
      question: "What should I do if I find outdated information on Birr Value?",
      answer:
        "We strive to keep our data accurate and up-to-date. If you notice outdated information, please contact us via our feedback form, and we will address the issue promptly.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>FAQ | Birr Value - Currency Exchange Rates & AI Market Analysis</title>
        <meta
          name="description"
          content="Find answers to frequently asked questions about Birr Value, including how we collect data, update exchange rates, and provide AI-driven market analysis."
        />
        <meta
          name="keywords"
          content="Birr Value FAQ, Ethiopian exchange rates, AI market analysis, currency converter, CBE rates, Awash Bank rates, forex trends Ethiopia"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": faqData.map((faq) => ({
              "@type": "Question",
              "name": faq.question,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer,
              },
            })),
          })}
        </script>
      </Helmet>

      <Box p="4" pt={{ base: '80px', md: '100px' }} color="#fff">
        <Text fontSize="32px" color="#fff" fontWeight="bold" mb="18px">
          Frequently Asked Questions
        </Text>

        <Accordion allowToggle>
          {faqData.map((faq, index) => (
            <AccordionItem key={index} borderColor="gray.600">
              <h2>
                <AccordionButton _expanded={{ bg: 'gray.700', color: 'white' }}>
                  <Box flex="1" textAlign="left" fontWeight="bold">
                    {faq.question}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} color="gray.300">
                {faq.answer}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </>
  );
};

export default FAQPage;
