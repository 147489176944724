import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Text,
  Skeleton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import baseUrl from "utils/constants";

const CurrencyRatesTable = ({ selectedBank, currencyLogos }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedBank) {
      setIsLoading(true); 
      setError(null); 
      fetch(
        `${baseUrl}/exchange_rate?bank_name=${encodeURIComponent(selectedBank)}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            setError("our site is under maintainance please try again");
            setData(null);
          } else if (!data.rates || data.rates.length === 0) {
            setError("No data available for the selected bank.");
            setData(null);
          } else {
            setData(data);
          }
          setIsLoading(false); 
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setError("Failed to load data. Please try again later.");
          setIsLoading(false); 
        });
    }
  }, [selectedBank]);

  if (isLoading) {
    return (
      <Table variant="simple" color="#fff">
        <Thead>
          <Tr my=".8rem" ps="0px">
            <Th
              color="gray.400"
              fontFamily="Plus Jakarta Display"
              borderBottomColor="#56577A"
            >
              <Skeleton height="20px" />
            </Th>
            <Th
              color="gray.400"
              fontFamily="Plus Jakarta Display"
              borderBottomColor="#56577A"
            >
              <Skeleton height="20px" />
            </Th>
            <Th
              color="gray.400"
              fontFamily="Plus Jakarta Display"
              borderBottomColor="#56577A"
            >
              <Skeleton height="20px" />
            </Th>
            <Th
              color="gray.400"
              fontFamily="Plus Jakarta Display"
              borderBottomColor="#56577A"
            >
              <Skeleton height="20px" />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {[...Array(8)].map((_, index) => (
            <Tr key={index}>
              <Td borderBottomColor="#56577A">
                <Skeleton height="20px" />
              </Td>
              <Td borderBottomColor="#56577A">
                <Skeleton height="20px" />
              </Td>
              <Td borderBottomColor="#56577A">
                <Skeleton height="20px" />
              </Td>
              <Td borderBottomColor="#56577A">
                <Skeleton height="20px" />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  }

  if (error) {
    return (
      <Alert status="error" variant="solid">
        <AlertIcon />
        <AlertTitle>Error!</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  if (!data) {
    return (
      <Alert status="warning" variant="solid">
        <AlertIcon />
        <AlertTitle>No Data</AlertTitle>
        <AlertDescription>No data available for the selected bank.</AlertDescription>
      </Alert>
    );
  }

  return (
    <Table variant="simple" color="#fff">
      <Thead>
        <Tr my=".8rem" ps="0px">
          <Th
            color="gray.400"
            fontFamily="Plus Jakarta Display"
            borderBottomColor="#56577A"
          >
            Currency Code
          </Th>
          <Th
            color="gray.400"
            fontFamily="Plus Jakarta Display"
            borderBottomColor="#56577A"
          >
            Currency Name
          </Th>
          <Th
            color="gray.400"
            fontFamily="Plus Jakarta Display"
            borderBottomColor="#56577A"
          >
            Buying
          </Th>
          <Th
            color="gray.400"
            fontFamily="Plus Jakarta Display"
            borderBottomColor="#56577A"
          >
            Selling
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.rates.map((rate, index) => {
          const lastItem = index === data.rates.length - 1;
          const currencyCode = rate["Currency Code"];

          const { logo, name } = currencyLogos[currencyCode] || {};

          return (
            <Tr key={index}>
              <Td borderBottomColor="#56577A" border={lastItem ? "none" : null}>
                <Flex
                  align="center"
                  py=".8rem"
                  minWidth="100%"
                  flexWrap="nowrap"
                >
                  {logo && (
                    <img
                      src={logo}
                      alt={`${currencyCode} logo`}
                      height="24px"
                      width="24px"
                      style={{ marginRight: "18px" }}
                    />
                  )}
                  <Text
                    fontSize="sm"
                    color="#fff"
                    fontWeight="bold"
                    minWidth="100%"
                  >
                    {currencyCode}
                  </Text>
                </Flex>
              </Td>
              <Td borderBottomColor="#56577A" border={lastItem ? "none" : null}>
                <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".5rem">
                  {name}
                </Text>
              </Td>
              <Td borderBottomColor="#56577A" border={lastItem ? "none" : null}>
                <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".5rem">
                  {rate.Buying}
                </Text>
              </Td>
              <Td borderBottomColor="#56577A" border={lastItem ? "none" : null}>
                <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".5rem">
                  {rate.Selling}
                </Text>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default CurrencyRatesTable;
